import {Player} from '@lottiefiles/react-lottie-player';
import {useEffect, useRef, useState} from 'react';

interface Props {
  src: string;
  width?: string;
  height?: string;
  style?: {[key: string]: string};
  play?: boolean;
  loop?: boolean;
}

const LottiePlayer = ({src, width, height, style, play, loop}: Props) => {
  const playerRef = useRef<Player>(null);
  const [isReady, setIsReady] = useState(false);

  const playerStyle = {
    width: width ?? '100%',
    height: height ?? '100%',
    ...style,
  };

  useEffect(() => {
    if (play && isReady) {
      playerRef.current?.play();
    }
  }, [play, isReady]);

  return (
    <Player
      src={src}
      style={playerStyle}
      keepLastFrame={true}
      ref={playerRef}
      autoplay={false}
      loop={loop ?? false}
      lottieRef={() => {
        setIsReady(true);
      }}
    />
  );
};

export default LottiePlayer;
