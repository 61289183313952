import cx from 'classnames';
import Image from 'next/legacy/image';

interface FeatureBlockProps {
  title: string;
  description: string;
  image: JSX.Element;
  flip?: boolean;
}

const FeatureBlock = ({title, description, image, flip = false}: FeatureBlockProps) => {
  return (
    <div
      className={cx(
        'flex flex-col items-center justify-center gap-6 lg:flex-row lg:gap-16 lg:py-20',
        {
          'lg:flex-row-reverse': flip,
        }
      )}
    >
      <div className="flex flex-col gap-5">
        <div className="text-xl lg:max-w-[481px] lg:text-3xl">{title}</div>
        <div className="text-brand-gray font-sans lg:max-w-[491px] lg:text-xl">{description}</div>
      </div>
      <div className="relative overflow-hidden lg:max-w-[98vw]">{image}</div>
    </div>
  );
};

const features: FeatureBlockProps[] = [
  {
    title: 'Prometheus-like Query Language',
    description:
      'Slice-and-dice continuous profiling data using the selector-based language you already know and love from Prometheus. Add arbitrary dimensions to your data to make it reflect your organization, the tool will not force your organization to change.',
    image: (
      <Image
        width={608}
        height={413}
        src="/features/prometheus-like-query-language.png"
        className="m-0"
      />
    ),
  },
  {
    title: 'Global View',
    description:
      'Polar Signals Cloud allows you to view all profiling data across your entire infrastructure, this enables powerful workflows that allow you to find things to optimize that actually matter and will make a difference.',
    image: <Image width={608} height={413} src="/features/global-view.png" className="m-0" />,
    flip: true,
  },
  {
    title: 'Filter by function',
    description:
      'You already know what you want to optimize? Great! Use the filter-by-function functionality, and you have the profiling data to inform your development process available immediately. No manual profiling, or recreating of past scenarios, the data is already there!',
    image: (
      <Image width={608} height={413} src="/features/filter-by-function.png" className="m-0" />
    ),
  },
  {
    title: 'Compare',
    description:
      'Polar Signals Cloud allows comparing the result of any two queries against each other. Compare the CPU time of version X with version Y (to understand a performance regression), compare datacenter A with datacenter B, or just understand what code was executing at one point in time of a process vs. another point in time.',
    image: <Image width={608} height={413} src="/features/compare.png" className="m-0" />,
    flip: true,
  },
];

export const SecondaryFeatures = () => {
  return (
    <>
      {features.map(feature => (
        <FeatureBlock {...feature} key={feature.title} />
      ))}
    </>
  );
};
