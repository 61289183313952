import {PrimaryFeatures} from './PrimaryFeatures';
import {SecondaryFeatures} from './SecondaryFeatures';

const CloudFeatures = () => {
  return (
    <div id="features" className="bg-gray-800">
      <div className="container mx-auto flex flex-col gap-12 px-4 py-12 text-white md:px-12 lg:gap-20 lg:py-32">
        <div className="flex flex-col items-center gap-4">
          <div className="lg:text-4.5xl text-center text-3xl font-semibold">
            Custom-built to handle your unique profiling needs
          </div>
          <div className="text-brand-gray max-w-3xl text-center font-sans text-base font-normal lg:text-xl">
            Polar Signals Cloud employs an exceptional blend of technologies, purpose-built to
            deliver the profiling toolset essential for today's evolving infrastructure and
            applications.
          </div>
        </div>
        <PrimaryFeatures />
        <SecondaryFeatures />
      </div>
    </div>
  );
};

export default CloudFeatures;
