import React, {LegacyRef, Ref, useEffect, useState} from 'react';

import cx from 'classnames';
import LottiePlayer from 'components/LottiePlayer';
import {useIntersectionObserver} from '@uidotdev/usehooks';

import RubyLogo from './assets/ruby.svg';
import RustLogo from './assets/rust.svg';
import CLogo from './assets/c.svg';
import CPlusPlusLogo from './assets/c-plus-plus.svg';
import PythonLogo from './assets/python.svg';
import GoLogo from './assets/go.svg';
import JavaLogo from './assets/java.svg';
import DotNet from './assets/dotnet.svg';
import Erlang from './assets/erlang.svg';
import Nodejs from './assets/nodejs.svg';

interface FullWidthPaneProps {
  title: string;
  description: string;
  image: JSX.Element;
  className?: string;
  imageContainerClassName?: string;
}

const Logos = () => {
  return (
    <div className="grid grid-cols-3 gap-x-12 gap-y-8 md:grid-cols-5 md:gap-y-12 lg:grid-cols-5 lg:gap-y-12">
      <div className="h-[30px] lg:h-[40px]">
        <CPlusPlusLogo className="h-[30px] lg:h-[40px]" />
      </div>
      <div className="h-[30px] lg:h-[40px]">
        <CLogo className="h-[30px] lg:h-[40px]" />
      </div>
      <div className="h-[30px] lg:h-[40px]">
        <GoLogo className="h-[30px] lg:h-[40px]" />
      </div>
      <div className="h-[30px] lg:h-[40px]">
        <RubyLogo className="h-[30px] lg:h-[40px]" />
      </div>
      <div className="h-[30px] lg:h-[40px]">
        <PythonLogo className="h-[30px] lg:h-[40px]" />
      </div>
      <div className="h-[30px] lg:h-[40px]">
        <JavaLogo className="h-[30px] lg:h-[40px]" />
      </div>
      <div className="h-[30px] lg:h-[40px]">
        <RustLogo className="h-[30px] lg:h-[40px]" />
      </div>
      <div className="h-[30px] lg:h-[40px]">
        <DotNet className="h-[30px] lg:h-[40px]" />
      </div>
      <div className="h-[30px] lg:h-[40px]">
        <Erlang className="h-[30px] lg:h-[40px]" />
      </div>
      <div className="h-[30px] lg:h-[40px]">
        <Nodejs className="h-[30px] lg:h-[40px]" />
      </div>
    </div>
  );
};

const FullWidthPane = React.forwardRef(function FullWidthPaneInner(
  {title, description, image, className = '', imageContainerClassName = ''}: FullWidthPaneProps,
  ref?: LegacyRef<HTMLDivElement>
) {
  return (
    <div
      className={cx(
        'relative flex h-[520px] w-full flex-col justify-between gap-3 overflow-hidden rounded-xl pl-4 pt-8 md:pt-10 md:pl-5 lg:h-[420px] lg:flex-row lg:items-center lg:gap-3 lg:pl-16 lg:pt-16',
        {
          [className]: !!className,
        }
      )}
    >
      <div className="flex flex-col gap-6 pr-4 lg:pb-16">
        <div className="text-2xl font-semibold lg:text-3xl">{title}</div>
        <div className="text-brand-gray font-sans text-base lg:max-w-[460px] lg:text-xl">
          {description}
        </div>
      </div>

      <div
        className={cx(
          'flex items-end justify-end rounded-tl-xl lg:ml-0 lg:h-full',
          {
            [imageContainerClassName]: !!imageContainerClassName,
          },
          title === 'Always on Profiler' && 'justify-center',
          title === 'Production Monitoring' &&
            'pt-11.5 h-auto rounded-tl-xl bg-gradient-to-br from-[#11182700] to-[#517c87] pl-11 md:absolute md:bottom-0 md:right-0 md:w-fit lg:mt-auto lg:h-[313px]'
        )}
        ref={ref}
      >
        {image}
      </div>
    </div>
  );
});

interface HalfWidthPaneProps {
  title: string;
  description: string;
  image: JSX.Element;
  className?: string;
  imageContainerClassName?: string;
}

const HalfWidthPane = React.forwardRef(function HalfWidthPaneInner(
  {title, description, image, className = '', imageContainerClassName = ''}: HalfWidthPaneProps,
  ref?: LegacyRef<HTMLDivElement>
) {
  return (
    <div
      className={cx(
        'relative h-[560px] w-full items-center justify-between rounded-xl lg:h-[520px] lg:w-1/2',
        {
          [className]: !!className,
        }
      )}
    >
      <div className="relative flex h-full w-full flex-col justify-between gap-6 overflow-hidden rounded-xl bg-[#11182766] px-4 pt-8 md:px-5 md:pt-10 lg:px-10">
        <div className="flex flex-col gap-6">
          <div className="text-2xl font-semibold">{title}</div>
          <div
            className={cx(
              title === 'Zero Instrumentation' && 'lg:max-w-[550px]',
              'text-brand-gray font-sans text-base lg:max-w-[460px] lg:text-xl'
            )}
          >
            {description}
          </div>
        </div>
        <div
          className={cx(
            'flex items-end justify-end bg-[#E6E9E6] lg:ml-0',
            {
              [imageContainerClassName]: !!imageContainerClassName,
            },
            title === 'Zero Instrumentation' &&
              'absolute right-[-40px] bottom-0 flex h-[278px] w-full !items-start !justify-start rounded-tl-xl px-8 pt-8 md:h-[200px] lg:right-0 lg:h-[278px] lg:w-[calc(100%-4rem)] lg:pt-12',
            title === 'Difference Detection' &&
              'absolute bottom-0 left-10 h-auto rounded-tl-xl md:absolute md:left-auto md:right-0 md:w-fit lg:relative lg:left-0 lg:h-[278px] lg:w-full lg:rounded-tr-xl'
          )}
          ref={ref}
        >
          {image}
        </div>
      </div>
    </div>
  );
});

export const PrimaryFeatures = () => {
  const [ref1, animation1] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '-150px',
  });
  const [ref2, animation2] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '-150px',
  });
  const [ref3, animation3] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '-150px',
  });
  const [ref4, animation4] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '-150px',
  });

  const [playAnimation1, setPlayAnimation1] = useState(false);
  const [playAnimation2, setPlayAnimation2] = useState(false);
  const [playAnimation3, setPlayAnimation3] = useState(false);
  const [playAnimation4, setPlayAnimation4] = useState(false);

  useEffect(() => {
    if (animation1?.isIntersecting && !playAnimation1) {
      setPlayAnimation1(true);
    }

    if (animation2?.isIntersecting && !playAnimation2) {
      setPlayAnimation2(true);
    }

    if (animation3?.isIntersecting && !playAnimation3) {
      setPlayAnimation3(true);
    }

    if (animation4?.isIntersecting && !playAnimation4) {
      setPlayAnimation4(true);
    }
  }, [
    animation1,
    playAnimation1,
    animation2,
    playAnimation2,
    animation3,
    playAnimation3,
    animation4,
    playAnimation4,
  ]);

  return (
    <div className="flex flex-col items-center gap-10 lg:gap-16">
      <FullWidthPane
        title="Always on Profiler"
        description="Our profiling technology is built on top of eBPF, and that means, we are continuously monitoring and collecting performance data from the system, with a very low overhead (<1%)."
        image={
          <LottiePlayer
            src="/features/always-on-profiler.json"
            height="400px"
            style={{marginBottom: '-33px'}}
            play={playAnimation1}
            loop={true}
          />
        }
        className="bg-gradient-to-br from-[#4C68F6] via-[#609FF4] to-[#74D7F2]"
        ref={ref1 as Ref<HTMLDivElement>}
      />
      <div className="flex w-full flex-col justify-center gap-10 lg:flex-row lg:gap-6">
        <HalfWidthPane
          title="Zero Instrumentation"
          description="With a zero instrumentation setup, deploy immediately and reap the benefits of actionable observability data. We support languages like C, C++, Go, Ruby, Python, Java, Rust, and many more."
          image={<Logos />}
          className="bg-gradient-to-tr from-[#74D7F2] via-[#74D7F2] to-[#A7EAD5]"
          ref={ref2 as Ref<HTMLDivElement>}
        />
        <HalfWidthPane
          title="Difference Detection"
          description="Our difference detection feature easily detects performance regressions, optimizations, or potential issues introduced in the codebase or application over time."
          image={<LottiePlayer src="/features/diff-detection.json" play={playAnimation3} />}
          className="bg-gradient-to-tr from-[#74D7F2] via-[#4C68F6] to-[#4C68F6]"
          ref={ref3 as Ref<HTMLDivElement>}
        />
      </div>
      <FullWidthPane
        title="Production Monitoring"
        description="Monitor production environments to detect anomalies, capture detailed profiling data, and improve performance during runtime."
        image={<LottiePlayer src="/features/production-monitoring.json" play={playAnimation4} />}
        className="bg-gradient-to-b from-[#FFEDEFB3] to-[#A7EAD5CC]"
        ref={ref4 as Ref<HTMLDivElement>}
      />
    </div>
  );
};
